import { z } from 'zod';

export const Tenant = z.object({
    id: z.string(),
    name: z.string(),
    tenants: z.string().array().nullish(),
    type: z.string(),
    value: z.string().nullish(),
});
export type Tenant = z.infer<typeof Tenant>;

export const Tenants = z.array(Tenant);
export type Tenants = z.infer<typeof Tenants>;
