import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './CustomerMark.module.less';
import c from 'classnames';

type CustomerMarkProps = {
    unmarked?: boolean;
    size?: number;
    animate?: boolean;
};

const defaultContainerSize = 16;
const defaultMarkSize = defaultContainerSize - 2;
const defaultIconSize = defaultMarkSize - 6;

const CustomerMark = ({ unmarked, size, animate = true }: CustomerMarkProps) => {
    const containerSize = (size && size * defaultContainerSize) || defaultContainerSize;
    const markSize = (size && size * defaultMarkSize) || defaultMarkSize;
    const iconSize = (size && size * defaultIconSize) || defaultIconSize;

    return (
        <div
            className={c(css.container, animate && css.animate)}
            style={{
                backgroundColor: `${unmarked ? 'black' : 'white'}`,
                width: `${containerSize}px`,
                height: `${containerSize}px`,
                cursor: `${animate ? 'pointer' : 'default'}`,
            }}
        >
            <div
                className={css.mark}
                style={{ backgroundColor: `${unmarked ? 'white' : 'black'}`, width: `${markSize}px`, height: `${markSize}px` }}
            >
                <FontAwesomeIcon
                    icon={faUser}
                    className={css.icon}
                    color={unmarked ? 'black' : 'white'}
                    style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
                />
            </div>
        </div>
    );
};

export default CustomerMark;
