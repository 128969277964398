// @ts-nocheck
import _ from 'lodash';
import { formatDeviceName } from '../util/deviceName';
import { CLOSE_SESSION, UPDATE_CUSTOMER_MARK, UPDATE_DEVICE_LOCATIONS, UPDATE_WEIGHTS } from './sessionMonitor.action';
import {
    ADD_BASKET_CHANGE,
    ADD_IMAGE,
    ADD_TARA_CHANGE,
    ADD_TICKET_CHANGE,
    PAYMENT_REQUEST_CHANGED,
    PAYMENT_REQUEST_CREATED,
    SESSION_SCALE_TRIGGER,
    SESSION_WEIGHT_DEVIATION,
    SESSION_WEIGHT_WITHOUT_SCAN,
    SET_SESSION_LOADING,
    UPDATE_SESSION_COMPLETE,
    UPDATE_SESSION_FAILED,
    SESSION_MANUAL_CHECK,
} from './sessionMonitorDetails.action';

const processBasketChange = (item) => {
    const {
        delta,
        basketItem: { basketItemGuid },
        modifiedOn,
        createdOn,
        changeGuid,
    } = item;
    if (delta === 0) return [];

    return {
        ...item,
        id: `${basketItemGuid}:${changeGuid}`,
        type: 'basketChange',
        time: modifiedOn || createdOn,
        receivedOn: new Date(),
        images: item.basketItem?.basketMetadataList?.find((x) => x.correlationGuid === item.changeGuid)?.images,
    };
};

const processTicketChange = (item) => {
    const { closedOn, id, openedOn } = item;

    const history = [];
    history.unshift({
        ...item,
        status: 0,
        historyId: `${id}:opened`,
        type: 'ticketChange',
        time: openedOn,
    });

    if (closedOn) {
        history.unshift({
            ...item,
            historyId: `${id}:closed`,
            type: 'ticketChange',
            time: closedOn,
        });
    }

    return history;
};

const processTaraChange = (item) => {
    const { createdOn } = item;

    return {
        ...item,
        id: `tara:${createdOn}`,
        type: 'tara',
        time: createdOn,
    };
};

const processScaleTrigger = (item) => {
    const { createdOn } = item;

    return {
        ...item,
        id: `scaleTrigger:${createdOn}`,
        type: 'scaleTrigger',
        time: createdOn,
    };
};

const processWeightWithoutScan = (item) => {
    return {
        ...item,
        id: `weightWithoutScan:${item?.model?.createdOn || item?.createdOn}`,
        type: 'weightWithoutScan',
        time: item?.model?.createdOn || item?.createdOn,
        weight: item?.model?.weight || item?.weight,
        isSilent: item?.model?.isSilent || item?.isSilent,
    };
};

const processManualCheck = (item) => {
    return item;
};

const processCustomerMark = (item) => {
    return item;
};

const processWeightDeviation = (item) => {
    const { createdOn } = item;

    return {
        ...item,
        id: `weightDeviation:${createdOn}`,
        type: 'weightDeviation',
        time: createdOn,
    };
};

const processSessionEvents = (session) => {
    const items = [];

    items.push({
        ...session,
        id: 'sessionStart',
        type: 'sessionStart',
        time: session.startedOn,
        ...session.history.sessionStartImages,
    });

    if (session.endedOn) {
        items.push({
            ...session,
            id: 'sessionEnd',
            type: 'sessionEnd',
            time: session.endedOn,
            ...session.history.sessionEndImages,
        });
    }

    for (const paymentRequest of session.history.paymentRequests) {
        items.push({
            ...paymentRequest,
            id: `${paymentRequest.paymentRequestGuid}:start`,
            type: 'paymentStart',
            time: paymentRequest.createdOn,
        });

        if (paymentRequest.completedOn || paymentRequest.status > 2) {
            items.push({
                ...paymentRequest,
                id: `${paymentRequest.paymentRequestGuid}:end`,
                type: 'paymentEnd',
                time: paymentRequest.completedOn ?? paymentRequest.modifiedOn,
            });
        }
    }

    return items;
};

const processAsyncAvailableImages = (asyncAvailableImages, { logicalPath, host, relativePath }) => {
    const result = { ...asyncAvailableImages };
    result[logicalPath] = `${host}${relativePath}`;
    return result;
};

const update = (state, sessionGuid, update, upsert) => {
    if (!state[sessionGuid] && !upsert) return state;

    const session = update(state[sessionGuid]);

    session.deviceId = formatDeviceName(session.isMobile ? String(session.sequence) : session.cartId, session.isMobile);

    session.history = _(session.history)
        .orderBy('receivedOn', 'desc')
        .uniqBy('id')
        .orderBy('time', 'desc')
        .map((item) => {
            if (item.images) {
                const availableImages = _.uniq(
                    (item.availableImages ?? []).concat(item.images.map((path) => session.asyncAvailableImages?.[path]).filter(Boolean)),
                );

                item = { ...item, availableImages };
            }

            return item;
        })
        .value();

    session.items = _(session.history)
        .filter((item) => item.type === 'basketChange')
        .orderBy('time', 'desc')
        .map('basketItem')
        .uniqBy('basketItemGuid')
        .orderBy('createdOn', 'desc')
        .value();

    return { ...state, [sessionGuid]: session };
};

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_SESSION_LOADING:
            return { ...state, [payload.sessionGuid]: { ...state[payload.sessionGuid], isLoading: payload.loading } };

        case UPDATE_SESSION_COMPLETE:
            const history = [].concat(
                ...payload.history.basketChanges.map(processBasketChange),
                ...payload.history.tickets.map(processTicketChange),
                ...payload.history.taras.map(processTaraChange),
                ...(payload.history.scaleTriggers?.map(processScaleTrigger) ?? []),
                ...(payload.weightWithoutScanAlerts?.map(processWeightWithoutScan) ?? []),
                ...(payload.history.weightDeviations?.map(processWeightDeviation) ?? []),
                ...processSessionEvents(payload),
            );
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    ...payload,
                    history: !processCustomerMark(payload.customer)?.labels?.includes('IsSuspicious')
                        ? history?.filter((t) => t?.category !== 'taggedCustomer')
                        : history,
                    manualCheck: processManualCheck(payload.manualCheck),
                    customer: processCustomerMark(payload.customer),
                    tickets: !processCustomerMark(payload.customer)?.labels?.includes('IsSuspicious')
                        ? payload?.history?.tickets?.filter((t) => t?.category !== 'taggedCustomer')
                        : payload?.history?.tickets,
                    isLoading: false,
                }),
                true,
            );

        case UPDATE_SESSION_FAILED:
            return { state, [payload.sessionGuid]: { ...payload, isLoading: false } };

        case ADD_BASKET_CHANGE:
            return update(
                state,
                payload.basketItem.sessionGuid,
                (s) => ({
                    ...s,
                    history: s.history.concat(processBasketChange(payload)),
                }),
                undefined,
            );

        case ADD_TICKET_CHANGE:
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    history: s.history.concat(processTicketChange(payload)),
                    tickets: s.tickets.filter((t) => t.id !== payload.id).concat(payload),
                }),
                undefined,
            );

        case ADD_TARA_CHANGE:
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    history: s.history.concat(processTaraChange(payload.tara)),
                }),
                undefined,
            );

        case ADD_IMAGE:
            return update(
                state,
                payload.properties.sessionGuid,
                (s) => ({
                    ...s,
                    asyncAvailableImages: processAsyncAvailableImages(s.asyncAvailableImages, payload),
                }),
                undefined,
            );

        case SESSION_SCALE_TRIGGER:
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    history: s.history.concat(processScaleTrigger(payload)),
                }),
                undefined,
            );

        case SESSION_WEIGHT_WITHOUT_SCAN:
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    history: s.history.concat(processWeightWithoutScan(payload)),
                }),
                undefined,
            );

        case SESSION_MANUAL_CHECK:
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    manualCheck: processManualCheck(payload),
                }),
                undefined,
            );

        case UPDATE_CUSTOMER_MARK:
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    customer: processCustomerMark(payload),
                    tickets: !payload?.labels?.includes('IsSuspicious')
                        ? s?.tickets?.filter((t) => t?.category !== 'taggedCustomer')
                        : s?.tickets,
                    history: !payload?.labels?.includes('IsSuspicious')
                        ? s?.history?.filter((h) => h?.category !== 'taggedCustomer')
                        : s?.history,
                }),
                undefined,
            );

        case SESSION_WEIGHT_DEVIATION:
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    history: s.history.concat(processWeightDeviation(payload)),
                }),
                undefined,
            );

        case UPDATE_WEIGHTS:
            return update(
                state,
                payload.sessionGuid,
                (s) => {
                    if (s.weightUpdatedOn > payload.t) return s;

                    return {
                        ...s,
                        actualWeight: payload.actualWeight ?? s.actualWeight,
                        expectedWeight: payload.expectedWeight ?? s.expectedWeight,
                        weightUpdatedOn: payload.t,
                    };
                },
                undefined,
            );

        case CLOSE_SESSION:
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    endedOn: payload.endedOn,
                    history: s.history.concat({
                        id: 'sessionEnd',
                        type: 'sessionEnd',
                        time: payload.endedOn,
                        images: payload.images,
                    }),
                }),
                undefined,
            );

        case PAYMENT_REQUEST_CREATED:
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    paymentRequests: s.paymentRequests.concat(payload),
                    history: s.history.concat({
                        ...payload,
                        id: `${payload.paymentRequestGuid}:start`,
                        type: 'paymentStart',
                        time: payload.createdOn,
                    }),
                }),
                undefined,
            );

        case PAYMENT_REQUEST_CHANGED:
            return update(
                state,
                payload.sessionGuid,
                (s) => ({
                    ...s,
                    paymentRequests: s.paymentRequests.filter((x) => x.paymentRequestGuid !== payload.paymentRequestGuid).concat(payload),
                    history: s.history.concat({
                        ...payload,
                        id: `${payload.paymentRequestGuid}:end`,
                        type: 'paymentEnd',
                        time: payload.completedOn ?? payload.modifiedOn,
                    }),
                }),
                undefined,
            );

        case UPDATE_DEVICE_LOCATIONS:
            return payload.reduce(
                (state, { sessionGuid, location }) =>
                    update(
                        state,
                        sessionGuid,
                        (s) => ({
                            ...s,
                            location,
                        }),
                        undefined,
                    ),
                state,
            );

        default:
            return state;
    }
};
