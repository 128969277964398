// @ts-nocheck
import { UPDATE_PUSH_STATUS } from './push.action';
import { ActivePushTopic, PushTopic } from '@push/types';

export default (
    state = {
        supported: undefined,
        permission: undefined,
        /* Store-specific topics (always subscribed to currently active store) */
        topics: [] as string[],
        /* Global topics (have defined store & stay subscribed there) */
        topicsGlobal: [] as PushTopic[],
        topicsActive: [] as ActivePushTopic[],
        inProgress: false,
        error: undefined,
    },
    { type, payload },
) => {
    switch (type) {
        case UPDATE_PUSH_STATUS:
            return { ...state, ...payload };
        default:
            return state;
    }
};
