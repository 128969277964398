import { parseAndFormatError } from '@whiz-cart/node-shared/errors/formatZodError';
import { DeepPartial } from '@whiz-cart/node-shared/types/deepPartial';
import { mergeWith, pick, set } from 'lodash';
import { ConfigEnv, type Config } from './configType';
import base from './envs/config.base';

export type Env = Record<string, string>;

declare global {
    /* eslint-disable no-var */
    var env: Env | string | undefined;
    var config: Config | undefined;
    var storeManagerRedirect: string | undefined;
    var serviceWorker: ServiceWorker | undefined;
    /* eslint-enable no-var */
}

const logPrefix = globalThis.serviceWorker ? '[SW] ' : '';

globalThis.env = {
    ...(typeof globalThis.env === 'string' ? undefined : globalThis.env),
    ...(import.meta.env.DEV ? import.meta.env : undefined),
};

const setFromEnv: Record<string, string> = {};
const parseEnvVarConfig = () => {
    const config: DeepPartial<Config> = {};

    if (import.meta.env.VITE_BACKENDS) {
        for (const part of import.meta.env.VITE_BACKENDS.split(',') as string[]) {
            const [, name = '', url = ''] = part.match(/^([^=]+)=(.*)$/) ?? [];
            set(config, `backends.${name}`, url);
            setFromEnv[`backends.${name}`] = url;
        }
    }

    for (const [key, value] of Object.entries(globalThis.env!)) {
        if (key === 'VITE_BACKENDS') {
            continue;
        }

        const path = key.replace('VITE_', '').replace('SMUI_CONFIG_', '').split('_');
        set(config, path, value);
        setFromEnv[path.join('.')] = value;
    }

    return config;
};

const envVarConfig = parseEnvVarConfig();
const overrideConfig = globalThis.config;

export default globalThis.config = parseAndFormatError(
    'config',
    ConfigEnv.passthrough(),
    mergeWith({}, base, overrideConfig, envVarConfig, (objValue, srcValue) => {
        if (objValue instanceof Array) return srcValue;
    }),
) as Config;

console.debug(`${logPrefix}Use env`, { ...pick(globalThis, 'env', 'config', 'storeManagerRedirect'), setFromEnv });

if (globalThis.storeManagerRedirect && globalThis.location) {
    console.info(`Redirecting to ${globalThis.storeManagerRedirect} ...`);
    globalThis.location.href = globalThis.storeManagerRedirect;
}
